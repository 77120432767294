import React from "react";
import Page from "../../components/Page";
import { graphql } from "gatsby";
import computerImage from "../../images/windows_computer.jpg";
import "../../styles/general.scss";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Studier({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;
  const title = "Plugg och studier: Lär dig plugga mer effektivt"

  return (
    <>
      <Page title={title} description="Få tips hur du kan bli mer effektiv och få mer gjort i dina studier. Lär dig hur man lär sig på bästa sätt.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="hundflytväst" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>
              När man pratar om lärande är ofta minnet ett bra ämne att börja
              med. Man brukar säga att vi människor har två olika typer av
              minnen. Det <b>semantiska minnet</b> som har att göra med våra
              faktakunskaper om världen och vårt <b>episodiska minne</b> som
              baseras på erfarenheter och är orienterat mot det förflutna, och
              kommer ofta ihågs som bilder.
            </p>
            <p>
              När vi lär oss någon ny fakta kommer alltså detta att lagras i det
              semantiska minnet, men för att komma dit måste hjärnan först anse
              att informationen är viktig att spara. För när vi först läser
              något kommer informationen först att lagras i vårt korttidsminne,
              eller även kallat <b>arbetsminne</b>, som lagrar den information som du
              fokuserar på i stunden, dvs. det du för tillfället aktivt tittar,
              lyssnar eller tänker på. Arbetsminnet är mycket litet och
              begränsat jämfört med långtidsminnet.
            </p>

            <p>
              Hur kan man då förflytta information från korttidsminnet till
              långtidsminnet? För att göra detta behöver som sagt hjärnan anse
              att informationen är viktig och det görs på bästa sätt genom
              upprepning, eller så kallad tidsfördelad repetition.
            </p>

            <p>
              <b>Tidsfördelad repetition</b> är en studieteknik som använder sig
              av materialet som man försöker lära sig repeteras med ett ökande
              tidsintervall. Repetitionstillfällen bör fördelas i gradvis ökande
              intervaller, ungefär mellanrum på en dag, två dagar, fyra dagar,
              åtta dagar och så vidare.
            </p>
            <h2>Andra metoder som snabbar upp inlärning</h2>
            <p>
              Om du känner att du inte presterar så bra som du borde göra, bör
              du fråga dig själv följande frågor:
            </p>
            <ul>
              <li>
                 Lägger jag ner den tiden på träning och rörelse som min kropp
                egentligen behöver?
              </li>
              <li> Har jag bra kostvanor och sover jag tillräckligt?</li>
              <li>
                 Pluggar jag på rätt sätt - eller finns det något bättre sätt
                jag kunnat göra det på?
              </li>
            </ul>
            <p>
              Kanske känns dessa frågorna konstiga, varför skulle rörelse,
              kostvanor och sömn vara relaterat till inlärning? Men faktum är
              att de alla har en <b>stor påverkan</b> på din hjärna, och är mycket
              viktiga för att din hjärna skall kunna fungera maximalt.
            </p>
            <h2>Studietekniker för att bli mer effektiv</h2>
            <p>
              Jag använder mig att flera olika studietekniker som jag brukar
              variera mellan. Nedan har jag listat 3 av mina favoriter, för att
              hitta fler studietekniker kan jag rekommendera sidan {" "}
              <a href="https://www.pluggtips.se/" target="_blank">
                pluggtips.se
              </a>
              .
            </p>

            <h3>Pomodoro-tekniken</h3>
            <p>
              <Link to={"/verktyg/pomodorotekniken/"}>Pomodoro-tekniken</Link>{" "}
              är en tidshanteringsteknik där en timer används för att dela upp
              arbetet i intervaller, traditionellt 25 minuter i längd. Följt av
              en paus på cirka 5 minuter. Tanken är att man arbetar effektivt
              under 25 minuter utan några störmoment alls för att sedan ta en
              kort paus. När jag själv pluggar brukar jag oftast köra 4 stycken
              pomodoros för att senare ta en lite längre paus.
            </p>
            <h3>Tankekartor</h3>
            <p>
              Tankekartor tycker jag är ett bra sätt för att få en bättre
              övergripande bild. Jag brukar ofta använda dem i början när jag
              försöker lära mig ett nytt ämne. Genom att använda tankekartor kan
              det bli lättare att förstå sammanhanget och lära sig mer om
              huvudämnen som sedan är viktiga för att kunna förstå detaljerna,
              som är "slutbubblorna" i din tankekarta.
            </p>
            <h3>Feynman-tekniken</h3>
            <p>
              <Link to={"/blogg/feynman-tekniken"}>Feynman-tekniken</Link> är en simpel men effektiv metod för att snabbt
              kunna lära sig ett nytt koncept eller ämne. Tekniken bygger på
              idén, “If you want to understand something well, try to explain it
              simply.” och bygger på att om du beskriver ett koncept med dina
              egna ord, kommer du att <Link to={"/blogg/lära-sig-snabbare/"}>lära dig det snabbare</Link>.
              .
            </p>
            <div className="imageFullContainer">
              <img
                className="imageFullWidth"
                src={computerImage}
                loading="lazy"
                alt="plugg studera"
              />
            </div>
            <h2>Några andra studietips</h2>
            <p>
              Nedan har jag också listat 5 snabba pluggtips. Kolla gärna in dem
              också för lite extra inspiration!
            </p>
            <h3>1. Ta fler pauser</h3>
            <p>
              Att ta pauser är viktigt för att vi skall kunna hålla fokus under
              en längre tid. En paus kan kort promenad på 5 minuter, att göra en
              kopp kaffe eller en kort powernap på 15 minuter. En teknik som är
              väldigt vanlig som använder sig av korta pauser är Pomodoro, som
              är en tidshanteringmetod där en timer används för att dela upp
              arbetet i intervaller, traditionellt 25 minuter i längd. Följt av
              en paus på cirka 5 minuter. Om du inte testat det kan jag starkt
              rekommendera att testa!
            </p>

            <h3>2. Skapa en bra studiemiljö</h3>
            <p>
              En bra studiemiljö är viktig för att vi skall kunna vara
              produktiva, men vad är en bra studiemiljö. För mig handlar det
              mycket om att skapa en bra miljö, detta kan göras genom att ha ett
              städat skrivbord och kanske tända ett ljus om man vill få lite
              bättre pluggkänsla. Sedan bör man även ta bort alla distraktioner,
              detta innefattar exempelvis din mobiltelefon.
            </p>
            <p> Ett annat sätt är
              att använda olika verktyg för att blockera sidor såsom Facebook
              och Youtube när du pluggar. Två sådana verktyg är
              exempelvis SelfControl och Block Site för Google Chrome.</p>

            <h3>3. Planera din dag</h3>
            <p>
              Att planera sin dag kan vara bra på ur två perspektiv, du kan få
              bättre förståelse på vad du lägger din tid på och kan därför hitta
              de sakerna som du lägger onödig tid på som du i stället skulle
              kunna använda till något bättre, såsom att studera, röra på dig
              eller något annat. Dessutom, genom att planera din dag och skriver
              ner dina åtaganden är det större chans att studierna blir av och
              du får gjort alla de sakerna som du behöver göra.
            </p>

            <h3>4. Prioritera din hälsa</h3>
            <p>
              Forskning visar att sömn, fysisk aktivitet och kost är otroligt
              viktig även när vi skall lära oss nya saker på ett effektivt sätt.
              Det finns otroligt många fördelar med att prioritera sin hälsa och
              detta är ett ämne som man hade kunnat skriva hur mycket som helst
              om.
            </p>
            <p>I regel bör man däremot se till att försöka få 8 timmar sömn,
              röra sig varje dag och helst få upp pulsen, samt äta en varierad
              och hälsosam kost. Om du vill veta mer rekommenderar jag att läsa
              mer om Sömn och Träning kopplat till produktivitet.</p>

            <h3>5. Sätt mål</h3>
            <p>
              Ibland kan det vara svårt att lägga bort alla distraktioner och då
              kan det vara en bra idé att sätta upp mål för dina studier och
              därefter ge dig själv en belöning när du genomfört ditt mål. Detta
              kanske inte låter som så mycket, men du kommer att bli förvånad
              över hur mycket det motiverar dig!
            </p>
            <br />
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}


export const query = graphql`
  query {
    image: file(relativePath: { eq: "studyIntro.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
